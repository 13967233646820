body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.b-color {
  height: 100vh;
  width: 100vw;
  background-color: rgba(38, 43, 95, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body {
  background-image: url("../public/assets/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  height: 100vh;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  position: relative;
  font-size: 35px;
  font-weight: bold;
}

#strip1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-repeat: repeat-x;

  background-size: cover;
  background-image: url("../public/assets/strip_1.png");
}

#strip2 {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: cover;
  background-image: url("../public/assets/strip_2.png");
}

.logo {
  width: 200px; /* Adjust as needed */
}

.under-construction-ar {
  margin-top: 5px; /* Adjust as needed */
}

.under-construction-en {
  margin-top: 5px; /* Adjust as needed */
}
